* {
  box-sizing: border-box;
}
html,
body {
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 400;
}
img {
  max-width: 100%;
}
.loader {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background: url('//upload.wikimedia.org/wikipedia/commons/thumb/e/e5/Phi_fenomeni.gif/50px-Phi_fenomeni.gif')
    50% 50% no-repeat rgb(249, 249, 249);
}

 
.p_container {
  max-width: 1210px;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
.d_flex {
  display: flex;
}
.d_align_center {
  align-items: center;
}
.d_justify_between {
  justify-content: space-between;
}
.d_justify_center {
  justify-content: center;
}
.text_dark {
  color: #000000;
}
.text_center {
  text-align: center;
}
h1.title {
  font-family: 'Heebo', sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 96px;
  line-height: 96px;
}
h1.title span {
  display: block;
  font-style: normal;
  font-weight: 500;
  font-size: 48px;
  line-height: 90px;
}
h1.title span + span {
  font-family: 'Heebo', sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 72px;
  line-height: 94px;
}
h2.title {
  font-family: 'Heebo', sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 64px;
  line-height: 94px;
  margin-bottom: 0;
}
h3.title {
  font-family: 'Heebo', sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 36px;
  line-height: 53px;
  color: #000000;
}
.subtitle {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #60636a;
}
.card_title {
  font-family: 'Heebo', sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 22px;
  line-height: 32px;
}
.card_text {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  color: #7d8693;
  margin-top: 10px;
}
.card_text_light {
  font-size: 12px;
  margin-top: 8px;
}
.nav {
  align-items: center;
}
.header_gamburger {
  display: none;
}
.header_nav__link {
  padding-right: 17.5px;
  padding-left: 17.5px;
  text-decoration: none;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #60636a;
  transition: 0.3s all ease;
}
.header_nav__link:hover {
  font-weight: 700;
  color: #ff763f;
  padding-right: 17px;
  padding-left: 17px;
}
.header_menu_btn {
  transition: 0.3s all ease;
}
.header_menu_btn:hover {
  opacity: 0.7;
}
.header_menu_btn:hover svg path {
  fill: #ff763f;
}
.btn {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  padding: 12px 25px;
  text-decoration: none;
  transition: 0.3s all ease;
  cursor: pointer;
}
.btn:hover {
  opacity: 0.7;
}
.btn_primary {
  background: #ff763f;
  color: #fff;
}
.btn_outline_primary {
  border: 2px solid #ff763f;
  color: #ff763f;
  background-color: transparent;
}
.btn_outline_light {
  border: 2px solid #ffffff;
  color: #fff;
  background-color: transparent;
}
.header_login {
  margin-left: auto;
}
.header_nav {
  margin: 0 auto;
  margin-left: 99px;
}
.header_btn {
  height: 44px;
}
.hero {
  padding-top: 40px;
  background-image: url('./img/hero_ground_2x.jpeg');
  background-size: cover;
  background-position: right top;
  padding-bottom: 22.23%;
}
.hero_title {
  max-width: 657px;
  margin-top: 14.3%;
  margin-bottom: 28px;
}
.hero_subtitle {
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 36px;
  color: #60636a;
}
.features {
  padding-bottom: 200px;
  padding-top: 55px;
}
.features_card {
  position: relative;
  z-index: 0;
  background: #ffffff;
  border: 1px solid #f3f3f3;
  border-radius: 12px;
  margin-right: 20px;
  width: 280px;
  padding: 45px 30px;
  transition: 0.3s all ease;
}
.features_card:hover {
  transform: translateY(-20px);
  background: #fff9f6;
  border: 1px solid #fbeee7;
}
.features_card:hover .features_icon {
  background-color: #fff;
  border-color: #fff;
}
.features_card:hover .features_icon svg path {
  fill: #e75013;
}
.features_card:hover .features_text {
  color: #60636a;
}
.features_card:last-child {
  margin-right: 0;
}
.features_card__link {
  position: absolute;
  z-index: 1;
  display: block;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  cursor: pointer;
}
.features_cards {
  padding-top: 80px;
}
.features_icon {
  width: 86px;
  height: 86px;
  min-width: 86px;
  min-height: 86px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-items: center;
  border: 1px solid #f3f3f3;
  border-radius: 50%;
  margin: 0 auto;
}
.features_header {
  margin-bottom: 24px;
}
.testimonials::before {
  content: '';
  display: block;
  position: absolute;
  width: 140px;
  height: 140px;
  background-image: url('./img/capsula.svg');
  background-position: center;
  background-size: contain;
  right: 55px;
  top: -51px;
}

.testimonials_avatar {
  position: relative;
  z-index: 0;
  min-width: 380px;
  width: 380px;
}
.testimonials_avatar::after {
  content: '';
  display: block;
  position: absolute;
  width: 100px;
  height: 100px;
  background-image: url('./img/circle_tablet.svg');
  background-size: contain;
  background-position: center;
  z-index: 1;
  bottom: -39px;
  left: -49px;
}
.testimonials {
  padding-top: 75px;
  padding-bottom: 75px;
  background-color: #fafafa;
  position: relative;
  z-index: 0;
  background-image: url('./img/casula_blur.svg');
  background-repeat: no-repeat;
  background-position: right 85%;
}
.testimonials_content {
  padding-left: 98px;
}
.testimonials_img {
  margin-bottom: 4px;
}
.testimonials_text {
  font-style: normal;
  font-size: 18px;
  line-height: 36px;
  color: #60636a;
}
.testimonials_data {
  margin-top: 20px;
}
.testimonials_autor {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  color: #000000;
}
.testimonials_data {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
}
.reason {
  padding-top: 143px;
  padding-bottom: 200px;
}
.reason_title {
  margin-top: 0;
}
.reason_container {
  margin: 0 auto;
  max-width: 1210px;
}
.reason_card {
  margin-top: 64px;
  position: relative;
  z-index: 0;
  border-radius: 8px;
  width: 306px;
  transition: 0.3s all ease;
  padding-top: 16px;
  padding-right: 40px;
  padding-bottom: 18px;
  padding-left: 16px;
  transition: 0.3s all ease;
}
.reason_card:hover {
  background: #ffffff;
  box-shadow: 0px 30px 65px -10px rgba(234, 234, 234, 0.3);
  transform: translateY(-10px);
}
.reason_card:hover .reason_icon {
  background: #fafafa;
}
.reason_card_link {
  position: absolute;
  display: block;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}
.reason_icon {
  min-width: 80px;
  min-height: 80px;
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #f3f3f3;
  border-radius: 8px;
}
.reason_content {
  padding-left: 20px;
}
.reason_card_title {
  font-family: 'Heebo', sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 22px;
  line-height: 32px;
  color: #000000;
}
.reason_card_text {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  color: #60636a;
  margin-top: 8px;
}
.contact_container {
  background: #ff763f;
  border-radius: 14px;
  padding-top: 51px;
  padding-right: 80px;
  padding-bottom: 50px;
  padding-left: 62px;
  max-width: 1180px;
}
.contact_img {
  margin-right: 37.3px;
}
.contact_img img {
  display: block;
}
.contact {
  padding-bottom: 200px;
}
.contact_form {
  width: 50%;
}
.contact_img {
  width: 50%;
}
.contact_form fieldset {
  display: block;
  width: 100%;
  margin: 0;
  padding: 0;
  border: none;
  margin-bottom: 20px;
}
.contact_form input,
.contact_form label,
.contact_form textarea {
  display: block;
  border: none;
  width: 100%;
}
.contact_submit {
  min-width: 100px;
  margin-top: 9px;
  height: 44px;
}
.contact_form label {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #ffffff;
  margin-bottom: 12px;
}
.contact_form input,
.contact_form textarea {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #ffffff;
  background: #ff8656;
  border-radius: 14px;
  height: 56px;
  padding: 16px;
}
.contact_title {
  font-family: 'Heebo', sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 64px;
  line-height: 94px;
  color: #ffffff;
}
.contact_subtitle {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  margin-bottom: 35px;
}
.footer {
  padding-top: 75px;
  padding-bottom: 86px;
  background: #f7f7f7;
}
.footer_title {
  font-family: 'Heebo', sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 22px;
  line-height: 32px;
  color: #60636a;
  margin-bottom: 14px;
}
.footer_content {
  max-width: 380px;
  margin-right: auto;
}
.footer_adresses {
  min-width: 220px;
  margin-left: 115px;
}
.footer_adress {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  color: #60636a;
}
.footer_logo {
  margin-bottom: 26px;
}
.footer_text {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  color: #7d8693;
  margin-bottom: 53px;
}
.footer_navlink {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  margin-left: 10px;
}
.footer_navlink:first-child {
  margin-left: 0;
}
.dashboard_content {
  width: 100%;
  padding-right: 70px;
  padding-left: 60px;
  padding-top: 39px;
}
.dashboard_nav {
  min-width: 450px;
  width: 450px;
  background: #fff;
  padding-left: 70px;
}
.dashboard_user {
  background: #fafafa;
  border-radius: 6px;
  padding: 20px 34px 20px 30px;
  max-width: 310px;
  margin-top: 44px;
}
.dashboard_avatar {
  min-width: 75px;
  min-height: 75px;
  width: 75px;
  height: 75px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 30px;
}
.dashboard_avatar img {
  display: block;
  width: 100%;
  height: 100%;
}
.dashboard_droplink {
  text-decoration: none;
  display: flex;
  align-items: center;
}
.dashboard_droplink span {
  font-family: 'Heebo', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 116%;
  color: #000000;
  margin-right: 4px;
}
.dashboard_droplink.active svg {
  transform: rotate(180deg);
}
.dashboard_welcome {
  font-family: 'Heebo', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 116%;
  color: #60636a;
}
.dashboard_button {
  position: relative;
  z-index: 0;
}
.dashboard_table {
  background: #fff;
  width: 100%;
}
.dashboard_copy {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 15px;
  text-align: right;
  color: #cebfb7;
  margin-top: 20px;
  margin-bottom: 30px;
}
.btn_rectangle {
  border-radius: 6px;
}
.btn_light {
  background-color: transparent;
  color: #ff763f;
}
.dashboard_btn_icon {
  font-family: 'Heebo', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  height: 66px;
  margin-top: 33px;
  width: 100%;
  max-width: 310px;
}
.dashboard_btn_icon svg {
  display: none;
}
.dashboard_btn_icon span + svg {
  display: block;
}
.dashboard_btn_icon span {
  margin-right: 6px;
}
.dashboard_navlink {
  cursor: pointer;
  display: flex;
  padding-left: 19px;
  text-decoration: none;
  padding-top: 18.5px;
  padding-bottom: 18.5px;
  transition: 0.3s all ease;
}
.dashboard_navlink:hover {
  opacity: 0.7;
}
.dashboard_navlink span {
  font-family: 'Heebo', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  color: #7d8693;
}
.dashboard_navlink svg {
  margin-right: 15px;
}
.dashboard_navlink.active {
  position: relative;
  z-index: 0;
}
.dashboard_navlink.active:after {
  content: '';
  display: block;
  position: absolute;
  z-index: 1;
  top: 18.5px;
  right: 0;
  width: 2px;
  height: 25px;
  background: #e84503;
}
.dashboard_navlink.active svg path {
  fill: #e84503;
}
.dashboard_navlink.active span {
  color: #363636;
}
.dashboard_navigation {
  margin-top: 36.5px;
}
.dashboard_nav {
  padding-top: 45px;
}
.dashboard_add {
  font-family: 'Poppins', sans-serif;
  height: 44px;
  padding-right: 21px;
  padding-left: 21px;
  margin-top: -5px;
}
.dashboard_add svg {
  margin-right: 7px;
}
.dashboard_remove {
  display: flex;
  min-width: 30px;
  min-height: 30px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: #ffeee6;
  align-items: center;
  justify-content: center;
  transition: 0.3s all ease;
  cursor: pointer;
}
.dashboard_remove:hover {
  opacity: 0.7;
}
.dashboard_main {
  margin-top: 38px;
  min-height: calc(100% - 156px);
  background-color: #fff;
  border-radius: 6px;
  overflow: hidden;
}
.dashboard_title {
  margin-top: 0;
  margin-bottom: 0;
  margin-right: 30px;
}
.dashboard {
  min-height: 100vh;
  background: #fff9f6;
}
.dashboard_logout {
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  margin-top: auto;
  padding-left: 19px;
  transition: 0.3s all ease;
}
.dashboard_logout:hover {
  opacity: 0.7;
}
.dashboard_logout svg {
  margin-right: 15px;
}
.dashboard_logout span {
  font-family: 'Heebo', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  color: #7d8693;
}
.dashboard_navigation {
  padding-bottom: 35px;
}
.dashboard_nav_box {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 65px;
}
.dashboard_table {
  border-collapse: collapse;
}
.dashboard_table td {
  border-bottom: 2px solid #fff9f6;
  padding: 22px 60px 20px 60px;
}
.dashboard_item {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #60636a;
}
.dashboard_title {
  display: flex;
  align-items: center;
}
.dashboard_title svg {
  margin-right: 10px;
  min-height: 16px;
  min-width: 16px;
  width: 16px;
  height: 16px;
}
.dashboard_title span {
  font-family: 'Heebo', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #7d8693;
}
.login {
  background-color: #fff9f6;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  padding: 116px 42px 30px 42px;
}
.login_copy {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 15px;
  color: #cebfb7;
  margin-top: auto;
}
.login_form {
  background-color: #fff;
  max-width: 680px;
  width: 100%;
  margin: auto;
  padding: 31px 50px 55px 50px;
  border-radius: 6px;
}
.login_form fieldset {
  padding: 0;
  border: none;
  position: relative;
  z-index: 0;
  margin-top: 20px;
}
.login_form label,
.login_form input {
  display: block;
  width: 100%;
}
.login_form label {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #7d8693;
  margin-bottom: 12px;
}
.login_form input {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #60636a;
  background: #fafafa;
  border-radius: 6px;
  border: none;
  height: 56px;
  padding: 16px 56px 16px 57px;
}
.login_email label + svg,
.login_pass label + svg {
  position: absolute;
  left: 18px;
  z-index: 1;
  bottom: 16px;
}
.login_pass input + svg {
  position: absolute;
  right: 16px;
  z-index: 1;
  bottom: 16px;
}
.login_btn {
  font-family: 'Heebo', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  width: 100%;
  border: none;
  height: 66px;
  margin-top: 53px;
}
.login_title {
  font-family: 'Heebo', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 44px;
  color: #000000;
  margin-bottom: 25px;
}
.login_failed {
  background: #fdf7f7;
  border-radius: 6px;
  padding-top: 12px;
  padding-bottom: 13px;
  margin-bottom: 28px;
}
.login_alert {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: #a86767;
}
.login_success {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: #7bbe8c;
}
.login_logo {
  margin-bottom: 20px;
}
.login_logo img {
  max-width: 412px;
  width: 100%;
}
.login_form .login_failed {
  display: none;
}
.login_form.error .login_failed {
  display: block;
}
.login_form.error .login_title {
  margin-top: 0;
}
.login_form .login_title {
  margin-top: 50px;
}
.testimonial_ava_tablet,
.testimonial_ava_mob {
  display: none;
}
.header_login_collapse,
.dashboard_logo img + img {
  display: none;
}
.dashboard_name {
  overflow: hidden;
  text-overflow: ellipsis;
}
.overlay_save,
.settings_form_btn,
.overlay_cancel {
  min-width: 100px;
  height: 56px;
  border: none;
}
.settings_form_btn {
  height: 42px;
  margin-left: 60px;
}
.settings_form {
  padding-top: 35px;
  padding-bottom: 35px;
}
.settings_title {
  font-family: 'Heebo', sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  padding-left: 60px;
  margin-bottom: 20px;
}

.overlay {
  background: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(4px);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 15px;
  display: none;
}

.overlay.active {
  display: block;
}
.overlay_form_wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
.overlay_form {
  margin: auto;
  max-width: 640px;
  width: 100%;
}
.overlay_form input {
  padding-left: 16px;
  padding-right: 16px;
}
.overlay_form {
  background-color: #fff;
  padding: 30px 40px;
  border-radius: 8px;
}
.overlay_group {
  margin-top: 25px;
}
.overlay_title {
  font-family: 'Heebo', sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 22px;
  line-height: 32px;
  color: #000000;
  margin-bottom: 16px;
}
.overlay_form fieldset {
  border: none;
  margin-bottom: 20px;
  padding: 0;
}
.overlay_form fieldset label {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #7d8693;
  margin-bottom: 12px;
}
.overlay_form fieldset input {
  background: #fafafa;
  border-radius: 6px;
  border: none;
  height: 56px;
}
.overlay_form fieldset label,
.overlay_form fieldset input {
  display: block;
  width: 100%;
}
.settings_show_btn {
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 1;
  right: 76px;
  bottom: 13px;
}
.settings_show_btn svg + svg {
  display: none;
}
.settings_form select,
.settings_form label,
.settings_form input {
  display: block;
  width: 100%;
  border: none;
}
.settings_form label {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #7d8693;
  margin-bottom: 12px;
}
.settings_form select,
.settings_form input {
  background: #fafafa;
  border-radius: 6px;
  padding-left: 16px;
  height: 42px;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
}
.settings_form fieldset {
  position: relative;
  z-index: 1;
  border: none;
  margin-bottom: 20px;
  padding-top: 0;
  padding-right: 60px;
  padding-bottom: 0;
  padding-left: 60px;
}
fieldset.settings_form_info {
  padding-top: 35px;
  padding-bottom: 35px;
  border-top: 2px solid #fff9f6;
  margin-bottom: 0;
}
.settings_form_info input {
  background: #f4f4f4;
}
fieldset.settings_form_info_first {
  margin-top: 35px;
}
fieldset.active .settings_show_btn svg {
  display: none;
}
fieldset.active .settings_show_btn svg + svg {
  display: block;
}
.forgot_link {
  display: flex;
  text-decoration: none;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #e84503;
  margin-top: 20px;
  justify-content: center;
  align-items: center;
}
.forgot_text {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  color: #7d8693;
  margin-bottom: 25px;
}
.forgot_title {
  margin-bottom: 5px;
}
.menu_overlay {
  display: none;
}
.header_nav.active + .menu_overlay {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  right: 0;
  bottom: 0;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 1;
}
@media screen and (max-width: 1376px) {
  .dashboard_nav {
    min-width: 277px;
    width: 277px;
    padding-left: 42px;
  }
  .dashboard_droplink span {
    font-size: 18px;
    line-height: 20.88px;
  }
  .dashboard_welcome {
    font-size: 14px;
    line-height: 16.24px;
  }
  .dashboard_user {
    padding: 20px 19px 20px 20px;
    max-width: 220px;
  }
  .dashboard_avatar {
    min-width: 50px;
    min-height: 50px;
    width: 50px;
    height: 50px;
    margin-right: 13px;
  }
  .dashboard_btn_icon {
    max-width: 220px;
  }
  .dashboard_logo {
    max-width: 187px;
  }
  h3.title {
    font-size: 22px;
    line-height: 32px;
  }
}
@media screen and (max-width: 1210px) {
  .dashboard_content {
    padding-right: 42px;
    padding-left: 20px;
  }
  .dashboard_table td {
    padding: 15px 20px;
  }
}
@media screen and (max-width: 1199px) {
  .reason_cards {
    flex-wrap: wrap;
    justify-content: center;
    max-width: 800px;
    margin-right: auto;
    margin-left: auto;
  }
}
@media screen and (max-width: 991px) {
  .forgot_link {
    margin-top: 15px;
  }
  .login_form {
    padding-top: 39px;
    padding-right: 85px;
    padding-left: 85px;
  }
  .dashboard_remove {
    min-width: 24px;
    min-height: 24px;
    width: 24px;
    height: 24px;
  }
  .dashboard_remove svg {
    width: 12px;
    height: 12px;
  }
  .dashboard_main {
    margin-top: 54px;
  }
  .dashboard_title span {
    font-size: 12px;
    line-height: 18px;
  }
  .dashboard_item {
    font-size: 10px;
    line-height: 15px;
  }
  .dashboard_table td {
    padding: 15px 12px;
    padding-right: 0;
  }
  .header_gamburger {
    display: block;
    margin-left: auto;
  }
  .dashboard_user {
    margin-top: 54px;
  }
  .header_nav {
    display: none;
    position: absolute;
    z-index: 3;
    background-color: #ffff;
    top: 70px;
    right: 0;
    padding: 20px 30px 40px 40px;
    transition: 0.3s all ease;
  }
  .header_nav__link {
    padding-top: 15px;
    padding-bottom: 15px;
    width: 100%;
    text-align: right;
  }
  .nav {
    flex-direction: column;
    align-items: flex-end;
  }
  .header_nav.active {
    display: block;
  }
  .header_login {
    display: none;
  }
  .hero {
    padding-top: 35px;
    padding-bottom: 77%;
    overflow-x: hidden;
    background-image: url('./img/header_table_bg_1.png');
  }
  .header_logo img {
    min-width: 280px;
    width: 280px;
  }
  .p_container {
    padding-right: 42px;
    padding-left: 42px;
  }
  .hero_title {
    margin-top: 18.3%;
  }
  h1.title {
    line-height: 78px;
  }
  h1.title span + span {
    margin-top: 15px;
  }
  .features_cards {
    margin-right: -42px;
  }
  .testimonials_container {
    flex-direction: column;
  }
  .testimonials_ava {
    display: none;
  }
  .testimonial_ava_tablet {
    display: block;
    width: 100%;
  }
  .testimonials_avatar {
    width: 100%;
  }
  .testimonials::before {
    display: none;
  }
  .features {
    padding-bottom: 194px;
  }
  .testimonials_content {
    padding-left: 0;
    margin-top: 80px;
  }
  .testimonials {
    background-image: none;
  }
  .reason {
    padding-top: 150px;
    padding-bottom: 103px;
  }
  .reason_cards {
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 64px;
  }
  .reason_card {
    margin-top: 0;
    margin-bottom: 26px;
  }
  .contact_container {
    background: none;
  }
  .contact_img {
    display: none;
  }
  .contact_form {
    width: 100%;
    padding-top: 46px;
    padding-right: 85px;
    padding-bottom: 204px;
    padding-left: 85px;
    background-color: #ff763f;
    border-radius: 14px;
    background-image: url('./img/form_bg_tablet.png');
    background-repeat: no-repeat;
    background-position: center calc(100% - 25px);
  }
  .contact_submit {
    margin-top: 16px;
  }
  .footer_container {
    flex-wrap: wrap;
  }
  .footer_content {
    display: flex;
    max-width: 100%;
    width: 100%;
  }
  .footer_content_wrapper {
    max-width: 380px;
  }
  .footer_nav {
    flex-wrap: wrap;
    min-height: 134px;
    width: 134px;
  }
  .footer_navlink {
    width: 36px;
    margin-right: 0;
    margin-bottom: 31px;
    margin-left: 31px;
    min-width: 36px;
    min-height: 36px;
  }
  .contact_container {
    padding-bottom: 0;
  }
  .footer_navlink:first-child {
    margin-left: 31px;
  }
  .footer {
    padding-top: 62px;
  }
  .footer_navlink svg {
    min-width: 36px;
    min-height: 36px;
  }
  .footer_social {
    margin-left: auto;
  }
  .footer_adresses {
    width: 50%;
    margin-top: 36px;
    margin-left: 0;
  }
  .footer_adresses + .footer_adresses {
    padding-left: 20px;
  }
  .header_login_collapse {
    display: block;
    text-align: right;
    margin-top: 20px;
  }
  .settings_form {
    padding-top: 24px;
  }
  .settings_form fieldset {
    padding-right: 22px;
    padding-left: 22px;
  }
  .settings_title {
    padding-left: 22px;
  }
  .settings_form_btn {
    margin-left: 22px;
  }
  .settings_show_btn {
    display: none;
  }
  fieldset.settings_form_info_first {
    margin-top: 25px;
  }
  fieldset.settings_form_info {
    padding-top: 25px;
    padding-bottom: 25px;
  }
}
@media screen and (max-width: 730px) {
  .dashboard_add {
    font-size: 11px;
    line-height: 16px;
    padding-right: 8px;
    padding-left: 8px;
    height: 28px;
    white-space: nowrap;
  }
  .dashboard_add svg {
    margin-right: 4px;
    width: 12px;
    height: 12px;
  }
}
@media screen and (max-width: 720px) {
  .dashboard_nav {
    padding-left: 20px;
  }
  .dashboard_content {
    padding-right: 20px;
  }
}
@media screen and (max-width: 700px) {
  .overlay_form {
    max-width: 343px;
    padding: 30px 25px;
  }
  .dashboard_btn_icon svg {
    display: block;
  }
  .dashboard_logout span,
  .dashboard_btn_icon span,
  .dashboard_btn_icon span + svg,
  .dashboard_navlink span,
  .dashboard_button {
    display: none;
  }
  .dashboard_user {
    padding: 12px 9px;
    max-width: 54px;
  }
  .dashboard_btn_icon {
    padding: 0;
    width: 54px;
    height: 54px;
  }
  .dashboard_avatar {
    min-width: 36px;
    min-height: 36px;
    width: 36px;
    height: 36px;
  }
  .dashboard_nav {
    padding-left: 6px;
    width: 66px;
    min-width: 66px;
  }
  .dashboard_navlink {
    padding-left: 15px;
  }
  .dashboard_logo {
    width: 26px;
    height: 24px;
    text-align: center;
    margin-left: 14px;
  }
  .dashboard_logo img {
    display: none;
  }
  .dashboard_logo img + img {
    display: block;
  }
}
@media screen and (max-width: 650px) {
  .settings_form {
    padding-top: 10px;
    padding-bottom: 20px;
  }
  .settings_form_btn {
    margin-left: 15px;
  }
  .settings_title {
    padding-left: 15px;
    margin-bottom: 15px;
  }
  .settings_form fieldset {
    padding-right: 15px;
    padding-left: 15px;
  }
  .settings_form fieldset {
    margin-bottom: 15px;
  }
  fieldset.settings_form_info {
    padding-bottom: 20px;
    padding-top: 20px;
  }
  fieldset.settings_form_info_first {
    margin-top: 20px;
  }
  fieldset.settings_form_info {
    margin-bottom: 0;
  }
  .login {
    padding-right: 16px;
    padding-left: 16px;
    padding-top: 20px;
  }
  .dashboard_nav_box {
    padding-bottom: 124px;
  }
  .login_logo {
    margin-right: auto;
    margin-left: auto;
    max-width: 230px;
  }
  .login_btn {
    margin-top: 29px;
  }
  .login_form .login_title {
    margin-top: 22px;
    margin-bottom: 25px;
  }
  .login_form .forgot_title {
    margin-top: 30px;
  }
  .login_form {
    padding-right: 30px;
    padding-left: 30px;
    padding-top: 37px;
    padding-bottom: 27px;
  }
  .login_form.error .login_logo {
    margin-bottom: 20px;
  }
  .login_failed {
    margin-bottom: 18px;
  }
  .login_btn {
    margin-top: 25px;
  }
  .dashboard_logout {
    padding-left: 16px;
  }
  .dashboard_nav {
    padding-top: 26px;
  }
  .dashboard_navlink {
    margin-bottom: 10px;
  }
  .dashboard_user {
    margin-top: 26px;
  }
  .dashboard_navigation {
    margin-top: 28px;
  }
  .dashboard_title svg {
    margin-right: 7px;
  }
  h3.title {
    font-size: 18px;
    line-height: 26px;
  }
  .dashboard_title {
    margin-right: 0;
  }

  .dashboard_table td {
    padding: 15px 0 15px 10px;
  }
  .dashboard_table tr > td:first-child {
    max-width: 86px;
  }
  .dashboard_table tr > td:nth-child(2) {
    max-width: 68px;
  }
  .dashboard_table .dashboard_body td:nth-child(3) {
    max-width: 39px;
  }
  .dashboard_table .dashboard_body td:last-child {
    padding-left: 0;
  }
  .dashboard_title span {
    white-space: nowrap;
  }
  .dashboard_item {
    font-size: 9px;
    line-height: 14px;
  }
  .dashboard_content {
    padding-right: 16px;
    padding-left: 8px;
    padding-top: 27px;
  }
  .dashboard_main {
    margin-top: 25px;
  }
  .h3.title {
    font-size: 18px;
    line-height: 26px;
  }
  .hero {
    padding-top: 26px;
    padding-bottom: 79%;
  }
  .header_logo img {
    min-width: 176px;
    width: 176px;
  }

  .p_container {
    padding-right: 16px;
    padding-left: 16px;
  }
  h1.title {
    font-size: 54px;
    line-height: 53px;
    margin-top: 24.3%;
    margin-bottom: 22px;
  }
  h1.title span {
    font-size: 36px;
    line-height: 58px;
  }
  h1.title span + span {
    font-size: 40px;
    line-height: 53px;
    margin-top: 13px;
  }
  .hero_subtitle {
    font-size: 16px;
    line-height: 24px;
  }
  h2.title {
    font-size: 36px;
    line-height: 53px;
  }
  .features {
    padding-top: 76px;
    padding-bottom: 166px;
  }
  .subtitle {
    font-size: 14px;
    line-height: 21px;
    margin-top: 10px;
  }
  .testimonial_ava_tablet {
    display: none;
  }
  .testimonial_ava_mob {
    display: block;
    width: 100%;
  }
  .testimonials_avatar {
    min-width: 100%;
  }
  .testimonials {
    padding-top: 56px;
  }
  .testimonials_avatar::after {
    width: 54px;
    height: 54px;
    bottom: -27px;
    left: -18px;
  }
  .testimonials_content {
    margin-top: 46px;
  }
  .testimonials_text {
    font-size: 12px;
    line-height: 24px;
  }
  .testimonials_img {
    margin-bottom: 5px;
  }
  .reason {
    padding-top: 119px;
    padding-bottom: 19px;
  }
  .reason_card {
    width: 100%;
    margin-bottom: 34px;
  }
  .reason_container {
    padding-right: 16px;
    padding-left: 16px;
  }
  .reason_cards {
    margin-top: 45px;
  }
  .contact_title {
    font-size: 36px;
    line-height: 53px;
    text-align: center;
  }
  .contact_form {
    padding-right: 25px;
    padding-left: 25px;
    padding-bottom: 160px;
    background-image: url('./img/form_bg_mobile.png');
    background-position: center calc(100% - 15px);
  }
  .contact_subtitle {
    text-align: center;
    font-size: 14px;
    line-height: 21px;
    margin-top: 10px;
    margin-bottom: 43px;
  }
  .contact {
    padding-bottom: 149px;
  }
  .footer_social {
    position: absolute;
    z-index: 1;
    right: 16px;
    bottom: 64px;
  }
  .footer {
    padding-top: 46px;
    position: relative;
  }
  .footer_adresses {
    width: 100%;
    margin-top: 33px;
  }
  .footer_text {
    margin-bottom: 17px;
  }
  .footer_adresses + .footer_adresses {
    padding-left: 0;
  }
}
@media screen and (max-width: 376px) {
  .hero {
    background-image: url('./img/hero_bg_mobile.png');
  }
}
